<template>
	<v-list dense>
		<slot/>
	</v-list>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort';
export default {
	name: "SortableList",
	mixins: [ContainerMixin],
}
</script>
