var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", color: "transparent" } },
    [
      _c("v-subheader", [_vm._v("Chance for seeing 2 cards")]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c("v-slider", {
            attrs: {
              min: "0",
              max: "1",
              step: "0.01",
              "prepend-icon": _vm.mdiChevronLeft,
              "append-icon": _vm.mdiChevronRight,
              "always-dirty": "",
              "thumb-label": "always"
            },
            on: {
              "click:prepend": _vm.click_left,
              "click:append": _vm.click_right,
              input: function($event) {
                return _vm.$emit("updated")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "thumb-label",
                fn: function(props) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(Math.round(props.value * 100)) + "%")
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.value.chance_double,
              callback: function($$v) {
                _vm.$set(_vm.value, "chance_double", $$v)
              },
              expression: "value.chance_double"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }