var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ExternalHeader",
        [
          _c("v-toolbar-title", [
            _vm._v(
              "Roles selected: " +
                _vm._s(_vm.roles.length) +
                " / " +
                _vm._s(_vm.desiredCount)
            )
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "", title: "Next" },
              on: { click: _vm.tryFinish }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiCheck))])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ExternalHeader",
        { attrs: { where: "header-ext" } },
        [
          _c(
            "v-tabs",
            {
              attrs: { "background-color": "transparent" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tab", { key: 0 }, [_vm._v("Players")]),
              _vm._v(" "),
              _c("v-tab", { key: 1 }, [_vm._v("Roles")])
            ],
            1
          ),
          _vm._v(" "),
          _vm.tab == 1
            ? _c("v-text-field", {
                attrs: { color: "secondary", clearable: "", label: "Filter" },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: 0 },
            [
              _c("PlayersEditor", {
                attrs: { players: _vm.players },
                on: {
                  "player-add": function($event) {
                    return _vm.$emit("player-add", $event)
                  },
                  "player-del": function($event) {
                    return _vm.$emit("player-del", $event)
                  },
                  "player-move": function($event) {
                    return _vm.$emit("player-move", $event)
                  },
                  "player-reverse": function($event) {
                    return _vm.$emit("player-reverse")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { key: 1 },
            [
              _c("RoleEditor", {
                attrs: {
                  filter: _vm.filter,
                  desiredCount: _vm.desiredCount,
                  rulesets: _vm.rulesets,
                  roles: _vm.roles,
                  roles_available: _vm.roles_available,
                  settings: _vm.settings
                },
                on: {
                  "roles-changed": function($event) {
                    return _vm.$emit("roles-changed", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "pink", text: "" },
                        on: {
                          click: function($event) {
                            _vm.error_shown = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("Close")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.error_shown,
            callback: function($$v) {
              _vm.error_shown = $$v
            },
            expression: "error_shown"
          }
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.error_text) + "\n\t\t")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }