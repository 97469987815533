<template>
	<v-icon>{{icon_data}}</v-icon>
</template>

<script>
import {
	mdiSecurity,
} from '@mdi/js';

const icons = {
	SentinelProtected: mdiSecurity,
};

export default {
	name: "Icon",
	props: {
		icon: String,
	},
	computed: {
		icon_data() {
			return icons[this.icon];
		},
	},
}
</script>
