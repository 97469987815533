<template>
	<RandomizationSettings
		header="Where to steal the card from?"
		:strings="['Player', 'Center', 'Do nothing']"
		:values="value.chances"
		@updated="$emit('updated')"
	/>
</template>

<script>
import RandomizationSettings from './RandomizationSettings.vue';

export default {
	name: "BodySnatcherSettings",
	components: {
		RandomizationSettings,
	},
	props: {
		value: Object,
	},
}
</script>
