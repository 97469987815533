import offline from 'offline-plugin/runtime';

import {Ui} from "./ui.js"
import {handle_onuw_error} from './panic.js'
const libPromise = import("../target_onuw_pkg_" + WASM_DIR + "/index");

window.onerror = e => {
	console.error(e);
	libPromise
		.then(lib => {
			lib.panic(e.toString(), e.stack || "No stack trace available");
		})
		.catch(e => console.error("Error panicing: ", e));
};

window.handle_onuw_error = handle_onuw_error;

let params = new URLSearchParams(location.hash.slice(1));

let ui = new Ui(sendToController);

let controller = libPromise.then(lib => {
	lib.init();

	let device_id = params.get("device_id") || +localStorage.device_id;
	if (!device_id) {
		let array = new Uint32Array(1);
		window.crypto.getRandomValues(array);
		device_id = localStorage.device_id = array[0];
	}

	return new lib.WebController(device_id, ui);
});

function sendToController(msg) {
	controller.then(c => c.event(msg))
		.catch(e => console.error("Failed sendToController", e));
}

let room = params.get("room");
if (room) {
	ui.set({
		room,
		main: {
			Connecting: room,
		},
	});
	sendToController({Join: room});
} else {
	ui.set({
		room: null,
		main: {
			Start: {
				context: "Werewolf",
				history: [],
				new_history_len: 0,
				choices: {
					question: "",
					items: [
						{
							id: "\"Network\"",
							label: "Start game",
							enable: true,
							icons: []
						},
						{
							id: "\"Join\"",
							label: "Join game",
							enable: true,
							icons: []
						},
						{
							id: "\"Local\"",
							label: "Local game",
							enable: true,
							icons: []
						}
					]
				}
			}
		}
	});
}

let debug = params.get("debug");
if (debug) {
	sendToController({Debug: JSON.parse(debug)});
}

offline.install({
	onUpdateReady: () => {
		ui.updateVersion();
	},
	onUpdated: () => {
		window.location.reload();
	},
});
offline.update();
