var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    _vm._l(_vm.value.artifacts, function(artifact) {
      return _c(
        "v-list-item",
        {
          key: artifact.id,
          on: {
            "!click": function($event) {
              $event.stopPropagation()
              artifact.enabled = !artifact.enabled
              _vm.$emit("updated")
            }
          }
        },
        [
          _c("v-list-item-content", [
            _vm._v("\n\t\t\t" + _vm._s(artifact.name) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c(
            "v-list-item-action",
            [
              _c("v-checkbox", {
                model: {
                  value: artifact.enabled,
                  callback: function($$v) {
                    _vm.$set(artifact, "enabled", $$v)
                  },
                  expression: "artifact.enabled"
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }