var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        { staticClass: "role-row" },
        [
          _c(
            "v-list-item-action",
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": 500 },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "", small: "" } }, on),
                            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiHelpCircle))])],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.help,
                    callback: function($$v) {
                      _vm.help = $$v
                    },
                    expression: "help"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v(_vm._s(_vm.role.name))]
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [_vm._v(_vm._s(_vm.role.desc))]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.help = false
                                }
                              }
                            },
                            [_vm._v("Ok")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-list-item-content", [_vm._v(_vm._s(_vm.role.name))]),
          _vm._v(" "),
          _vm.settings
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      _vm.settingsVisible = !_vm.settingsVisible
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDotsVertical))])],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.role.id === "Villager"
            ? _c("v-list-item-action", [
                _vm._v(_vm._s(_vm.vacancies) + " (the rest)")
              ])
            : _vm.role.multiple_allowed === "Sure"
            ? [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", small: "", disabled: _vm.value === 0 },
                    on: {
                      click: function($event) {
                        return _vm.$emit("minus")
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiChevronLeft))])],
                  1
                ),
                _vm._v("\n\t\t\t" + _vm._s(_vm.value) + "\n\t\t\t"),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      small: "",
                      disabled: _vm.value >= _vm.max
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("plus")
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v(_vm._s(_vm.mdiChevronRight))])],
                  1
                )
              ]
            : _c(
                "v-list-item-action",
                [
                  _c("v-switch", {
                    attrs: {
                      inputValue: _vm.value,
                      trueValue: 1,
                      falseValue: 0
                    },
                    on: { change: _vm.toggle }
                  })
                ],
                1
              )
        ],
        2
      ),
      _vm._v(" "),
      _vm.settings
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.settingsVisible,
                  expression: "settingsVisible"
                }
              ],
              staticClass: "whole-settings-panel"
            },
            [
              _c("div", { staticClass: "pre-settings-panel" }),
              _vm._v(" "),
              _c(_vm.role.id + "Settings", {
                tag: "component",
                staticClass: "settings-panel",
                on: {
                  updated: function($event) {
                    return _vm.$emit("updated")
                  }
                },
                model: {
                  value: _vm.settings,
                  callback: function($$v) {
                    _vm.settings = $$v
                  },
                  expression: "settings"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "post-settings-panel" })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }