<template>
	<v-layout column>
		<RandomizationSettings
			:strings="strings"
			:values="value.chances"
			@updated="$emit('updated')"
		/>
		<v-list>
			<v-list-item @click.capture.stop="value.selftap = !value.selftap; $emit('updated')">
				<v-list-item-content>
					Aliens can turn themselves into an Alien or Alien Helper?
				</v-list-item-content>
				<v-list-item-action>
					<v-checkbox v-model="value.selftap"/>
				</v-list-item-action>
			</v-list-item>
			<v-list-item @click.capture.stop="value.helperstop = !value.helperstop; $emit('updated')">
				<v-list-item-content>
					Alien Helper stops being an Alien if they were?
				</v-list-item-content>
				<v-list-item-action>
					<v-checkbox v-model="value.helperstop"/>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-layout>
</template>

<script>
import RandomizationSettings from './RandomizationSettings.vue';

export default {
	name: "AlienSettings",
	components: {
		RandomizationSettings,
	},
	props: {
		value: Object,
	},
	data() {
		return {
			strings: [
				"View a card each",
				"View a card together",
				"Stare at each other",
				"Half day timer",
				"Give the card to left",
				"Give the card to right",
				"Show your card",
				"New Alien",
				"Alien Helper",
			],
		}
	},
}
</script>
