<template>
	<v-list-item>
		<slot/>
	</v-list-item>
</template>

<script>
import { ElementMixin } from 'vue-slicksort';
export default {
	name: "SortableListItem",
	mixins: [ElementMixin],
}
</script>
