<template>
	<v-list>
		<v-list-item v-for="artifact in value.artifacts" :key="artifact.id" @click.capture.stop="artifact.enabled = !artifact.enabled; $emit('updated')">
			<v-list-item-content>
				{{artifact.name}}
			</v-list-item-content>
			<v-list-item-action>
				<v-checkbox v-model="artifact.enabled"/>
			</v-list-item-action>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: "CuratorSettings",
	props: {
		value: Object,
	}
}
</script>
