var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "results" },
    [
      _c(
        "ExternalHeader",
        [
          _c("v-toolbar-title", [
            _vm._v("Results "),
            _vm.epic_battle ? _c("span", [_vm._v("- Epic Battle!")]) : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("ExternalHeader", { attrs: { where: "header-ext" } }),
      _vm._v(" "),
      _c(
        "v-data-table",
        {
          attrs: {
            items: _vm.indexedPlayers,
            "hide-default-footer": "",
            "hide-default-header": "",
            "items-per-page": _vm.players.length
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function(ref) {
                var item = ref.item
                return _c(
                  "tr",
                  {
                    class: [
                      {
                        "last-player": item.index + 1 === _vm.num_players,
                        "first-player": item.index === 0
                      },
                      "result-row"
                    ]
                  },
                  [
                    _c(
                      "td",
                      [
                        item.won
                          ? _c("v-icon", { style: _vm.style(item.index) }, [
                              _vm._v(_vm._s(_vm.mdiStar))
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        class: _vm.cls(item.index),
                        style: _vm.style(item.index)
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        class: _vm.cls(item.index),
                        style: _vm.style(item.index)
                      },
                      [_vm._v(_vm._s(item.starting_role))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        class: _vm.cls(item.index),
                        style: _vm.style(item.index)
                      },
                      [_vm._v(_vm._s(item.ending_role))]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        class: _vm.cls(item.voted_for),
                        style: _vm.style(item.voted_for)
                      },
                      [_vm._v(_vm._s((_vm.players[item.voted_for] || {}).name))]
                    )
                  ]
                )
              }
            }
          ])
        },
        [
          _c("template", { slot: "header" }, [
            _c("th"),
            _vm._v(" "),
            _c("th", [_vm._v("Player")]),
            _vm._v(" "),
            _c("th", [_vm._v("Started as")]),
            _vm._v(" "),
            _c("th", [_vm._v("Ended up")]),
            _vm._v(" "),
            _c("th", [_vm._v("Voted for")])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("Restart", {
        on: {
          "play-again": function($event) {
            return _vm.$emit("again")
          },
          "play-again-settings": function($event) {
            return _vm.$emit("settings")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }