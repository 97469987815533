<template>
	<div>
	<ExternalHeader>
		<v-toolbar-title>Werewolf</v-toolbar-title>
	</ExternalHeader>
	<ExternalHeader where="header-ext" />
	<ButtonList
		@selected="$emit('selected', $event)"
		:buttonKey="0"
		:items="buttons"
		color="primary"
		/>
	</div>
</template>

<script>
import ExternalHeader from './ExternalHeader.vue';
import ButtonList from './ButtonList.vue';

export default {
	name: "Start",
	components: {
		ExternalHeader,
		ButtonList,
	},
	props: {
		buttons: Array,
	},
}
</script>
