var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.history && _vm.history.length
        ? _c("Summary", {
            attrs: {
              history: _vm.history,
              new_history_len: _vm.new_history_len
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-subheader", [_vm._v(_vm._s(_vm.question))]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          { key: _vm.buttonKey },
          _vm._l(_vm.items, function(item) {
            return _c(
              "v-layout",
              { key: item.id, attrs: { column: "", "pt-3": "" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "icons",
                    on: {
                      click: function($event) {
                        return _vm.click(item)
                      }
                    }
                  },
                  _vm._l(item.icons, function(icon) {
                    return _c("Icon", {
                      key: icon,
                      class: { enabled: item.enable },
                      attrs: { icon: icon }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: !item.enable, color: _vm.color },
                    on: {
                      click: function($event) {
                        return _vm.click(item)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(item.label) + "\n\t\t\t")]
                )
              ],
              1
            )
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }