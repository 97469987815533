var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c("RandomizationSettings", {
        attrs: { strings: _vm.strings, values: _vm.value.chances },
        on: {
          updated: function($event) {
            return _vm.$emit("updated")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              on: {
                "!click": function($event) {
                  $event.stopPropagation()
                  _vm.value.selftap = !_vm.value.selftap
                  _vm.$emit("updated")
                }
              }
            },
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n\t\t\t\tAliens can turn themselves into an Alien or Alien Helper?\n\t\t\t"
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.value.selftap,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "selftap", $$v)
                      },
                      expression: "value.selftap"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              on: {
                "!click": function($event) {
                  $event.stopPropagation()
                  _vm.value.helperstop = !_vm.value.helperstop
                  _vm.$emit("updated")
                }
              }
            },
            [
              _c("v-list-item-content", [
                _vm._v(
                  "\n\t\t\t\tAlien Helper stops being an Alien if they were?\n\t\t\t"
                )
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-action",
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.value.helperstop,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "helperstop", $$v)
                      },
                      expression: "value.helperstop"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }