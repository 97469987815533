<template>
	<RandomizationSettings
		:strings="['Witch', 'Village Idiot', 'Drunk', 'Troublemaker', 'Robber']"
		:values="value.chances"
		@updated="$emit('updated')"
	/>
</template>

<script>
import RandomizationSettings from './RandomizationSettings.vue';

export default {
	name: "RascalSettings",
	components: {
		RandomizationSettings,
	},
	props: {
		value: Object,
	},
}
</script>
