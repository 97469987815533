var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle-1" }, [_vm._v(_vm._s(_vm.round))]),
      _vm._v(" "),
      _vm.remote_players.length
        ? _c("v-subheader", [_vm._v("Local players")])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.local_players, function(item) {
        return _c(
          "v-layout",
          { key: item.id, attrs: { column: "", "pt-3": "" } },
          [
            _c(
              "div",
              {
                class: { icons: true, enabled: _vm.enabled(item) },
                on: {
                  click: function($event) {
                    return _vm.click(item)
                  }
                }
              },
              [
                item.done
                  ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiCheck))])
                  : item.locked
                  ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiClockOutline))])
                  : _c("v-icon", [_vm._v(_vm._s(_vm.mdiPlay))]),
                _vm._v(" "),
                _vm._l(item.icons, function(icon) {
                  return _c("Icon", { key: icon, attrs: { icon: icon } })
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { disabled: !_vm.enabled(item), color: "primary" },
                on: {
                  click: function($event) {
                    return _vm.click(item)
                  }
                }
              },
              [_vm._v("\n\t\t" + _vm._s(item.name) + "\n\t")]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.remote_players.length
        ? _c("v-subheader", [_vm._v("Remote players")])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.remote_players, function(item) {
        return _c(
          "v-layout",
          { key: item.id, attrs: { column: "", "pt-3": "" } },
          [
            _c(
              "div",
              {
                staticClass: "icons",
                on: {
                  click: function($event) {
                    return _vm.click(item)
                  }
                }
              },
              [
                item.done
                  ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiCheck))])
                  : item.locked
                  ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiClockOutline))])
                  : _c(
                      "v-btn",
                      { attrs: { fab: "", "x-small": "", color: "primary" } },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPlay))])],
                      1
                    )
              ],
              1
            ),
            _vm._v(" "),
            _c("v-btn", { attrs: { disabled: "", color: "primary" } }, [
              _vm._v("\n\t\t" + _vm._s(item.name) + "\n\t")
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }