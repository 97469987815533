import html2canvas from 'html2canvas';

const project_id = 8002844;
const token = 'vd7iR1WG8txzDKfzMS93';

let console_log = [];
let real_console_log = console.log.bind(console);
console.log = function(...args) {
	real_console_log(...args);
	console_log.push(args.join(' '));
}

let handled_error = false;

async function submit_crash(args, screenshot) {
	let result_area = document.getElementById('crashresult');
	result_area.innerText = 'Submiting...';

	let img = null;
	try {
		if (screenshot) {
			img = await new Promise(function(resolve) {
				let filename = Math.random().toString(36).substring(2, 15) + '.png';
				let xhr = new XMLHttpRequest();
				xhr.open('POST', `https://gitlab.com/api/v4/projects/${project_id}/uploads`, true);
				xhr.setRequestHeader('Private-Token', token);
				let form_data = new FormData();
				form_data.append('file', screenshot, filename);
				xhr.send(form_data);
				xhr.onreadystatechange = () => {
					if (xhr.readyState === 4) {
						resolve(JSON.parse(xhr.responseText));
					}
				};
			});
		}
	} catch(_) {
		// noop
	}

	let xhr = new XMLHttpRequest();
	xhr.open('POST', `https://gitlab.com/api/v4/projects/${project_id}/issues`, true);
	xhr.setRequestHeader('Content-Type', 'application/json');
	xhr.setRequestHeader('Private-Token', token);
	xhr.onreadystatechange = () => {
		result_area.innerText = xhr.status+' '+xhr.statusText;
	};
	xhr.onerror = e => {
		result_area.innerText = e.type;
	};
	let description = [
		'```',
		...args,
		navigator.userAgent,
		'```',
		img?.markdown,
		'```',
		...console_log,
		'```',
	].join('\n');
	xhr.send(JSON.stringify({
		title: 'Crash',
		description,
	}));
}

export async function handle_onuw_error(...args) {
	if (handled_error) return;
	handled_error = true;
	let screenshot = null;
	try {
		let canvas = await html2canvas(document.body);
		screenshot = await new Promise(function(resolve) {
			canvas.toBlob(resolve);
		});
	} catch(_) {
		// noop
	}
	document.head.innerHTML = '';
	document.body.innerHTML = [
		'ONUW has crashed! <a href=.>Home</a><br/>',
		'<button id=crashsubmit>Submit bug report</button>',
		'<pre id=crashresult></pre>',
		'<hr/>',
		'<pre id=crash></pre>',
		'<img id=crashscreen style="width: 20%; border-style: solid;" />',
	].join('');
	if (screenshot) {
		document.getElementById('crashscreen').src = URL.createObjectURL(screenshot);
	}
	document.getElementById('crash').innerText = args.join('\n');
	document.getElementById('crashsubmit').addEventListener('click', function() {
		this.style.display = 'none';
		submit_crash(args, screenshot);
	});
}
