<template>
<v-app>
	<v-navigation-drawer app v-model="menuVisible">
		<v-list nav>
			<v-list-item href=".">
				<v-list-item-action><v-icon>{{mdiHome}}</v-icon></v-list-item-action>
				<v-list-item-content>Leave Game</v-list-item-content>
			</v-list-item>
			<v-list-item @click="askRoom(false)">
				<v-list-item-action><v-icon>{{mdiAccountMultiple}}</v-icon></v-list-item-action>
				<v-list-item-content v-if="room">Room: {{room}}</v-list-item-content>
				<v-list-item-content v-else>Join Room</v-list-item-content>
				<v-list-item-action v-if="room"><v-icon>{{mdiPencil}}</v-icon></v-list-item-action>
			</v-list-item>
			<v-list-item v-if=game_active @click="restart()">
				<v-list-item-action><v-icon>{{mdiReplay}}</v-icon></v-list-item-action>
				<v-list-item-content>Restart Game</v-list-item-content>
			</v-list-item>
			<v-list-item @click="$emit('debug')">
				<v-list-item-action><v-icon>{{mdiWrench}}</v-icon></v-list-item-action>
				<v-list-item-content>Debug Mode</v-list-item-content>
			</v-list-item>
			<v-list-item :href="mailto">
				<v-list-item-action><v-icon>{{mdiBug}}</v-icon></v-list-item-action>
				<v-list-item-content>Feedback</v-list-item-content>
			</v-list-item>
			<v-list-item @click="changeLog('changelog-pinned.html')">
				<v-list-item-action><v-icon>{{mdiScriptTextOutline}}</v-icon></v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Change Log</v-list-item-title>
					<v-list-item-subtitle>{{version}}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="aboutShown = true">
				<v-list-item-action><v-icon>{{mdiInformation}}</v-icon></v-list-item-action>
				<v-list-item-content>About</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
	<v-app-bar app color="primary" dark>
		<v-app-bar-nav-icon @click.stop="menuVisible = !menuVisible"></v-app-bar-nav-icon>
		<span v-if="!menuVisible" class="pr-2">{{room}}</span>
		<slot name="header" />
		<slot name="header-ext" slot="extension" />
	</v-app-bar>
	<v-main ref="content">
		<v-container fluid>
			<slot />
		</v-container>
	</v-main>
	<Prompt title="Enter room code" label="Room" @ok="$emit('join-room-name', $event)" @cancel="joinCancelled" ref="join" :counter="4" uppercase/>
	<v-dialog v-model="restartShown" :max-width="400">
		<v-card>
			<v-layout justify-space-between>
				<v-card-title primary-title class="headline">Restart Game</v-card-title>
				<v-btn icon @click="restartShown = false"><v-icon>{{mdiClose}}</v-icon></v-btn>
			</v-layout>
			<Restart
				@play-again="restartAction('play-again', $event)"
				@play-again-settings="restartAction('play-again-settings', $event)"/>
		</v-card>
	</v-dialog>
	<v-dialog v-model="updateShown" :max-width="400">
		<v-card>
			<v-layout justify-space-between>
				<v-card-title primary-title class="headline">Update Game</v-card-title>
				<v-btn icon @click="updateShown = false"><v-icon>{{mdiClose}}</v-icon></v-btn>
			</v-layout>
			<div class="pa-5 pb-1">New version available.</div>
			<div class="pa-5 pt-1"><strong>Warning:</strong> If you are playing right now and choose to upgrade, you will need to restart the game.</div>
			<v-card-actions>
				<v-btn color="primary" @click="changeLog('changelog.html')">ChangeLog</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" outlined @click="updateShown = false">Later</v-btn>
				<v-btn color="secondary" @click="updateNow()">Update</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<CloseableDialog v-model="changeLogShown" scrollable header="Change Log">
		<v-card-text v-html="changeLogText" v-if="changeLogText" class="changelog"></v-card-text>
		<div v-else>
			<v-skeleton-loader type="article"></v-skeleton-loader>
			<v-skeleton-loader type="article"></v-skeleton-loader>
		</div>
	</CloseableDialog>
	<CloseableDialog v-model="aboutShown" :max-width="400" scrollable header="About Werewolf">
		<v-card-text>
			<p>This application lets you play <a href="https://beziergames.com/products/one-night-ultimate-werewolf" target="_blank">One Night Ultimate Werewolf</a> and its expansions by <a href="https://beziergames.com" target="_blank">Bezier Games</a> without closing your eyes for the "night".</p>
			<p>You can play using a single phone by passing it around the table, have every player use their own phone or anything in between.</p>
			<p>It is not affiliated with Bezier Games and made for friends. One of players is advised to buy the game from them.</p>
			<p>The previous incarnation of the project was at <a href="https://ultimatewerewolf.quis.cx" target="_blank">ultimatewerewolf.quis.cx</a>. It was rewritten from scratch because the old architecture couldn't accommodate all new roles.</p>
		</v-card-text>
	</CloseableDialog>
	<v-snackbar v-model="networkErrorShown" :timeout="-1" color=error>Cannot connect to server</v-snackbar>
	<v-snackbar v-model="incompatibleVersionErrorShown" :timeout=10000 color=error>
		Got an incompatible message from another player. All players must use the same version. After everyone updates the version, you are advised to switch to a new room.
	</v-snackbar>
</v-app>
</template>

<script>
import offline from 'offline-plugin/runtime';
import Prompt from './Prompt.vue';
import Restart from './Restart.vue';
import CloseableDialog from './CloseableDialog.vue';
import vhCheck from 'vh-check';
import {
	mdiClose,
	mdiBug,
	mdiWrench,
	mdiReplay,
	mdiPencil,
	mdiAccountMultiple,
	mdiHome,
	mdiScriptTextOutline,
	mdiInformation,
} from '@mdi/js';

vhCheck({
	bind: true,
	updateOnTouch: true,
});

export default {
	name: "Layout",
	components: {
		Prompt,
		Restart,
		CloseableDialog,
	},
	props: {
		game_active: Boolean,
		room: String,
		version: String,
	},
	data() {
		return {
			menuVisible: null,
			restartOnCancelJoin: false,
			restartShown: false,
			updateShown: false,
			changeLogShown: false,
			aboutShown: false,
			networkErrorShown: false,
			incompatibleVersionErrorShown: false,
			changeLogText: '',
			mdiClose,
			mdiBug,
			mdiWrench,
			mdiReplay,
			mdiPencil,
			mdiAccountMultiple,
			mdiHome,
			mdiScriptTextOutline,
			mdiInformation,
		};
	},
	computed: {
		mailto() {
			return "mailto:incoming+kevincox-onuw-8002844-962m6wxvuys4wkn4alewcycrq-issue@incoming.gitlab.com?subject=ONUW:%20&body=Version:%20ONUW%20" + this.version;
		},
	},
	methods: {
		reset() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		askRoom(restart) {
			this.restartOnCancelJoin = restart;
			this.$refs.join.shown = true;
		},
		joinCancelled() {
			if (this.restartOnCancelJoin) {
				this.$emit('restart');
			}
		},
		restart() {
			this.restartShown = true;
		},
		restartAction(...args) {
			this.restartShown = false;
			this.$emit(...args)
		},
		updateShow() {
			this.updateShown = true;
		},
		updateNow() {
			offline.applyUpdate();
		},
		showNetworkError(failed) {
			this.networkErrorShown = failed;
		},
		showIncompatibleVersionError() {
			this.incompatibleVersionErrorShown = true;
		},
		changeLog(file) {
			fetch(file).then(response => {
				if (response.ok) {
					response.text().then(text => {
						this.changeLogText = text;
					});
				} else {
					this.changeLogText = 'Could not load the log: ' + response.statusText;
				}
			});
			this.changeLogShown = true;
		},
	},
}
</script>

<style scoped>
#app {
	width: 100vw;
	height: calc(100vh - var(--vh-offset, 0px));
}
</style>

<style>
.v-tab, .v-btn {
	text-transform: none;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 1ex;
}
::-webkit-scrollbar-thumb {
	border-radius: 0.5ex;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
html {
	overscroll-behavior: contain;
}
.changelog h2 {
	padding-top: 10px;
}
.changelog ul {
	padding-bottom: 10px;
}
</style>

<style lang="scss">
</style>
