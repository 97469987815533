<script>
export default {
	name: "ExternalHeader",
	inject: ['registerHeader'],
	props: {
		where: {
			type: String,
			default: 'header',
		},
	},
	render(h) {
		this.registerHeader(this.where, this.$slots.default);
		return h();
	},
}
</script>
