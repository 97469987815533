var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-action",
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": 500 },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "", "x-small": "" } }, on),
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiHelpCircle))])],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.help,
                callback: function($$v) {
                  _vm.help = $$v
                },
                expression: "help"
              }
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [_vm._v(_vm._s(_vm.role.name))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pa-5" }, [
                    _vm._v(_vm._s(_vm.role.desc))
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.help = false
                            }
                          }
                        },
                        [_vm._v("Ok")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [
            _vm._v(_vm._s(_vm.role.name) + " "),
            _vm.role.num > 1
              ? _c("span", [_vm._v("x" + _vm._s(_vm.role.num))])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }