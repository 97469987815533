<template>
	<span>
		<span v-for="(piece, i) in data" :key="i">
			<span v-if="piece.Plain !== undefined">{{piece.Plain}}</span>
			<span v-if="piece.Popup !== undefined" @click="setPopupState(i, true)">
				<v-dialog v-model="popupOpen[i]" :max-width="500">
					<template v-slot:activator="{on}">
						<span class="popup-activator" v-on="on">{{piece.Popup.short}}</span>
					</template>
					<v-card>
						<v-card-title primary-title class="headline">{{piece.Popup.short}}</v-card-title>
						<div class="pa-5"><RichText :data="piece.Popup.description" /></div>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn outlined @click="setPopupState(i, false)" color="primary">Ok</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</span>
		</span>
	</span>
</template>

<script>
export default {
	name: "RichText",
	props: {
		data: Array,
	},
	data() {
		return {
			popupOpen: {},
		}
	},
	methods: {
		setPopupState(i, state) {
			this.$set(this.popupOpen, i, state);
		},
	},
}
</script>

<style>
.v-dialog__container {
	vertical-align: inherit;
}
</style>

<style scoped>
.popup-activator {
	text-decoration: underline;
	text-decoration-style: dotted;
}
</style>
