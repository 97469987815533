import { render, staticRenderFns } from "./PlayerSelector.vue?vue&type=template&id=0abba8c2&scoped=true&"
import script from "./PlayerSelector.vue?vue&type=script&lang=js&"
export * from "./PlayerSelector.vue?vue&type=script&lang=js&"
import style0 from "./PlayerSelector.vue?vue&type=style&index=0&id=0abba8c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abba8c2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VIcon,VLayout,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/builds/kevincox/onuw/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0abba8c2')) {
      api.createRecord('0abba8c2', component.options)
    } else {
      api.reload('0abba8c2', component.options)
    }
    module.hot.accept("./PlayerSelector.vue?vue&type=template&id=0abba8c2&scoped=true&", function () {
      api.rerender('0abba8c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/PlayerSelector.vue"
export default component.exports