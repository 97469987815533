<template>
	<v-list-item>
		<v-list-item-action>
			<v-dialog v-model="help" :max-width="500">
				<template v-slot:activator="{ on }">
					<v-btn icon x-small v-on="on"><v-icon>{{mdiHelpCircle}}</v-icon></v-btn>
				</template>
				<v-card>
					<v-card-title primary-title class="headline">{{role.name}}</v-card-title>
					<div class="pa-5">{{role.desc}}</div>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn outlined @click="help = false" color="primary">Ok</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-list-item-action>
		<v-list-item-content>
			<v-list-item-title>{{role.name}} <span v-if="role.num > 1">x{{role.num}}</span></v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
import {mdiHelpCircle} from '@mdi/js';

export default {
	props: {
		role: Object,
	},
	data() {
		return {
			help: false,
			mdiHelpCircle,
		}
	},
}
</script>

<style scoped>
.v-list-item__action {
	margin: 0;
}
.v-list-item {
	min-height: 0;
}
.v-list-item__content {
	padding: 0;
}
</style>
