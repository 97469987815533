var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RandomizationSettings", {
    attrs: {
      header: "Where to steal the card from?",
      strings: ["Player", "Center", "Do nothing"],
      values: _vm.value.chances
    },
    on: {
      updated: function($event) {
        return _vm.$emit("updated")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }