<template>
	<div class="results">
		<ExternalHeader>
			<v-toolbar-title>Results <span v-if="epic_battle">- Epic Battle!</span></v-toolbar-title>
		</ExternalHeader>
		<ExternalHeader where="header-ext" />
		<v-data-table :items="indexedPlayers" hide-default-footer hide-default-header :items-per-page="players.length">
			<template slot="header">
				<th></th>
				<th>Player</th>
				<th>Started as</th>
				<th>Ended up</th>
				<th>Voted for</th>
			</template>
			<tr slot="item" slot-scope="{item}" :class="[{'last-player': item.index + 1 === num_players, 'first-player': item.index === 0}, 'result-row']">
				<td><v-icon v-if="item.won" :style="style(item.index)">{{mdiStar}}</v-icon></td>
				<td :style="style(item.index)" :class="cls(item.index)">{{item.name}}</td>
				<td :style="style(item.index)" :class="cls(item.index)">{{item.starting_role}}</td>
				<td :style="style(item.index)" :class="cls(item.index)">{{item.ending_role}}</td>
				<!-- TODO: use optional chaining below when https://github.com/vuejs/vue/issues/8861 is fixed -->
				<td :style="style(item.voted_for)" :class="cls(item.voted_for)">{{(players[item.voted_for]||{}).name}}</td>
			</tr>
		</v-data-table>
		<Restart
			@play-again="$emit('again')"
			@play-again-settings="$emit('settings')"/>
	</div>
</template>

<script>
import ExternalHeader from './ExternalHeader.vue';
import Restart from './Restart.vue';
import {mdiStar} from '@mdi/js';

export default {
	name: "Results",
	components: {
		ExternalHeader,
		Restart,
	},
	props: {
		players: Array,
		num_players: Number,
		epic_battle: Boolean,
	},
	data() {
		return {
			mdiStar,
		};
	},
	computed: {
		indexedPlayers() {
			return this.players.map((p, index) => ({index, ...p}));
		},
	},
	methods: {
		style(index) {
			return {
				color: this.players[index]?.color,
			};
		},
		cls(index) {
			let player = this.players[index];
			return {
				killed: player?.dead,
				winner: player?.won,
			};
		},
	},
}
</script>

<style scoped>
.killed {
	text-decoration: line-through;
}
.winner {
	font-weight: bold;
}
th {
	text-align: left;
}
div.v-data-table table tbody tr.first-player td {
	border-top: 1px solid black;
}
div.v-data-table table tbody tr.last-player.result-row td {
	border-bottom: 1px solid black !important;
}
</style>

<style>
div.results .v-data-table table td, div.results .v-data-table table th, div.results .v-data-table table tr {
	height: 3em;
	padding: 3px;
}
</style>
