var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "title", attrs: { "primary-title": "" } },
        [_vm._v("The story so far")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "summary_container ml-4" },
        [
          _vm._l(_vm.history, function(ref, i) {
            var visibility = ref.visibility
            var text = ref.text
            return [
              _c(
                "v-icon",
                {
                  key: i + "-icon",
                  staticClass: "summary_icon",
                  attrs: { title: _vm.icon[visibility].hint },
                  on: {
                    click: function($event) {
                      _vm.legendShown = true
                    }
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.icon[visibility].icon) +
                      "\n\t\t\t"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { key: i + "-text" },
                [
                  _c("RichText", { attrs: { data: text } }),
                  _vm._v(" "),
                  i >= _vm.history.length - _vm.new_history_len
                    ? _c("strong", [_vm._v("(New!)")])
                    : _vm._e()
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "CloseableDialog",
        {
          attrs: { "max-width": 400, header: "Legend", scrollable: "" },
          model: {
            value: _vm.legendShown,
            callback: function($$v) {
              _vm.legendShown = $$v
            },
            expression: "legendShown"
          }
        },
        [
          _c("div", { staticClass: "summary_dialog" }, [
            _c(
              "div",
              { staticClass: "summary_container ma-3" },
              [
                _c("v-icon", { staticClass: "summary_icon" }, [
                  _vm._v(_vm._s(_vm.icon.Private.icon))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Information which at that moment was available only to you, or to a limited set of people"
                  )
                ]),
                _vm._v(" "),
                _c("v-icon", { staticClass: "summary_icon" }, [
                  _vm._v(_vm._s(_vm.icon.Visual.icon))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "What is visible on the table, but not publicly announced. Players learn it when opening eyes, e.g. while waking up during the night, or when the day begins"
                  )
                ]),
                _vm._v(" "),
                _c("v-icon", { staticClass: "summary_icon" }, [
                  _vm._v(_vm._s(_vm.icon.Public.icon))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("Publicly announced information, known to everyone")
                ])
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("v-card-actions", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }