<template>
	<div id="playerlistcontainer">
	<SortableList lockAxis="y" :value="players" :useDragHandle="true" @sort-end="dragged" class="list" appendTo="#playerlistcontainer">
	<transition-group :name="anim">
		<SortableListItem v-for="({name, local}, index) in players" :key="name" :index="index" class="item">
			<v-list-item-action v-handle class="handle"><v-icon>{{mdiDrag}}</v-icon></v-list-item-action>
			<v-list-item-content :class="{remote: !local}">{{name}}</v-list-item-content>
			<v-icon v-if="!local">{{mdiAntenna}}</v-icon>
			<v-list-item-action>
				<v-btn @click="$emit('player-del', name)" icon small><v-icon>{{mdiDelete}}</v-icon></v-btn>
			</v-list-item-action>
		</SortableListItem>
	</transition-group>
	</SortableList>
	<v-layout fab>
		<v-btn fab color="secondary" @click="$emit('player-reverse')" title="Reverse"><v-icon>{{mdiShuffle}}</v-icon></v-btn>
		<v-btn fab color="secondary" @click="$refs.add.shown = true" title="Add player"><v-icon>{{mdiAccountPlus}}</v-icon></v-btn>
	</v-layout>
	<Prompt title="New player" label="Name" @ok="$emit('player-add', $event)" ref="add"/>
	</div>
</template>

<script>
import Prompt from './Prompt.vue';
import SortableList from './SortableList.vue';
import SortableListItem from './SortableListItem.vue';
import { HandleDirective } from 'vue-slicksort';
import {
	mdiAccountPlus,
	mdiAntenna,
	mdiDelete,
	mdiDrag,
	mdiShuffle,
} from '@mdi/js';

export default {
	name: 'PlayersEditor',
	components: {
		Prompt,
		SortableList,
		SortableListItem,
	},
	directives: {
		handle: HandleDirective,
	},
	props: {
		players: Array,
	},
	data() {
		return {
			animating: true,
			mdiAccountPlus,
			mdiAntenna,
			mdiDelete,
			mdiDrag,
			mdiShuffle,
		};
	},
	computed: {
		anim() {
			return this.animating ? 'anim' : null;
		}
	},
	methods: {
		dragged({oldIndex, newIndex}) {
			if (oldIndex === newIndex) return;
			this.animating = false;
			this.$emit('player-move', {oldIndex, newIndex});
			setTimeout(() => {
				this.animating = true;
			}, 0);
		},
	},
}
</script>

<style scoped>
.list {
	border-top: 1px;
	border-bottom: 0px;
	border-left: 0px;
	border-right: 0px;
	border-color: lightgray;
	border-style: solid;
	padding-top: 0px;
	padding-bottom: 36px;
}
.item {
	border-top: 0px;
	border-bottom: 1px;
	border-left: 0px;
	border-right: 0px;
	border-color: lightgray;
	border-style: solid;
	transition: 0;
}
.handle {
	cursor: row-resize;
}
.anim-enter, .anim-leave-to {
	opacity: 0;
}
.anim-leave-active {
	position: absolute;
	width: 100%;
}
.anim-move {
	transition: transform 0.3s;
}
.v-list-item__action {
	margin: 0;
}
.fab {
	position: fixed;
	bottom: 0;
	right: 0;
}
.fab * {
	margin: 5px;
}
.remote {
	color: gray;
}
</style>
