var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.data, function(piece, i) {
      return _c("span", { key: i }, [
        piece.Plain !== undefined
          ? _c("span", [_vm._v(_vm._s(piece.Plain))])
          : _vm._e(),
        _vm._v(" "),
        piece.Popup !== undefined
          ? _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.setPopupState(i, true)
                  }
                }
              },
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": 500 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "span",
                                _vm._g({ staticClass: "popup-activator" }, on),
                                [_vm._v(_vm._s(piece.Popup.short))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.popupOpen[i],
                      callback: function($$v) {
                        _vm.$set(_vm.popupOpen, i, $$v)
                      },
                      expression: "popupOpen[i]"
                    }
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass: "headline",
                            attrs: { "primary-title": "" }
                          },
                          [_vm._v(_vm._s(piece.Popup.short))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pa-5" },
                          [
                            _c("RichText", {
                              attrs: { data: piece.Popup.description }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: { outlined: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.setPopupState(i, false)
                                  }
                                }
                              },
                              [_vm._v("Ok")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }