<template>
	<div>
	<Summary v-if="history && history.length" :history="history" :new_history_len="new_history_len" />
	<v-subheader>{{question}}</v-subheader>
	<transition name="fade" mode="out-in">
		<div :key="buttonKey">
			<v-layout column v-for="item in items" :key="item.id" pt-3>
				<div class="icons" @click="click(item)">
					<Icon v-for="icon in item.icons" :key="icon" :icon="icon" :class="{enabled: item.enable}" />
				</div>
				<v-btn :disabled="!item.enable" @click="click(item)" :color="color">
					{{item.label}}
				</v-btn>
			</v-layout>
		</div>
	</transition>
	</div>
</template>

<script>
import Icon from './Icon.vue';
import Summary from './Summary.vue';

export default {
	name: "ButtonList",
	components: {
		Icon,
		Summary,
	},
	props: {
		context: String,
		history: Array,
		new_history_len: Number,
		question: String,
		items: Array,
		buttonKey: Number,
		color: String,
	},
	methods: {
		click(item) {
			if (item.enable) {
				this.$emit('selected', item.id);
			}
		},
	},
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.1s ease;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.icons {
	position: absolute;
	/* match size of .v-btn */
	height: 30px;
	margin-left: 6px;
	margin-top: 6px;
	z-index: 1;
}
.icons * {
	line-height: 36px;
}
.icons .enabled {
	color: #FFFFFF;
}
html body div.v-application .v-icon {
	vertical-align: baseline;
}
</style>
