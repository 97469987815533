var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ExternalHeader", [_c("v-toolbar-title", [_vm._v("Werewolf")])], 1),
      _vm._v(" "),
      _c("ExternalHeader", { attrs: { where: "header-ext" } }),
      _vm._v(" "),
      _c("ButtonList", {
        attrs: { buttonKey: 0, items: _vm.buttons, color: "primary" },
        on: {
          selected: function($event) {
            return _vm.$emit("selected", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }