"use strict";

import 'babel-polyfill';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import App from './vue/App.vue';
import colors from 'vuetify/lib/util/colors';
import { library as faLibrary } from "@fortawesome/fontawesome-svg-core";
import { faMask, faBullhorn, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

faLibrary.add(faMask, faBullhorn, faEye);

Vue.use(Vuetify);

Vue.config.errorHandler = function(err, vm, info) {
	function stringifyVm(vm) {
		return `${vm.$options.name}(props: ${JSON.stringify(vm.$props)}, data: ${strData(vm.$data)})`
	}
	function strData(data) {
		// App component has another VM in its data, which has $root, leading to circular object.
		/* eslint-disable-next-line no-unused-vars */
		let {header, ...rest} = data;
		return JSON.stringify(rest);
	}
	if (typeof err === 'string') {
		err = {
			message: err,
			toString() {
				return this.message;
			}
		}
	}
	err.message += ` in ${stringifyVm(vm)} while ${info}`
	window.onerror(err);
};

// Extracts the type from a Rust enum. Returns a pair of [type: String, value: Object].
function extractType(rust_enum) {
	if (typeof rust_enum === "string") {
		return [rust_enum, undefined]
	}

	let entries = Object.entries(rust_enum);

	if (entries.length !== 1)
		console.log("Expected 1 key in enum", rust_enum);

	return entries[0];
}

const send = Symbol();
const debug = Symbol();
const vm = Symbol();
const seq = Symbol();

const game_views = {
	Summary(info) {
		this[vm].game_screen = 'summary';
		this[vm].summary = info;
	},

	Day({timer, roles, public_info, players}) {
		this[vm].game_screen = 'day';

		let started, timeout_ms;
		if (timer.Started) {
			started = true;
			timeout_ms = timer.Started.end_timestamp_ms - new Date;
		} else {
			started = false;
			timeout_ms = timer.Pending.secs * 1000;
		}

		let dusk_roles = [];
		let night_roles = [];
		let nowake_roles = [];
		const roles_by_time = {
			Dusk: dusk_roles,
			Night: night_roles,
			NoWake: nowake_roles,
		};
		let rolesCount = {};
		for (let r of roles) {
			rolesCount[r.id] = (rolesCount[r.id] || 0) + 1;
		}
		let rolesAdded = {};
		for (let r of roles) {
			if (rolesAdded[r.id] === undefined) {
				rolesAdded[r.id] = true;
				roles_by_time[r.wake_time].push({num: rolesCount[r.id], ...r});
			}
		}

		this[vm].day = {
			started,
			timeout_ms,
			dusk_roles,
			night_roles,
			nowake_roles,
			public_info,
			players,
		};
	},

	PlayerSelector(players) {
		this[vm].game_screen = 'PlayerSelector';
		this[vm].players = players;
		this[vm].$off('player-selected');
		this[vm].$once('player-selected', response => {
			this[vm].reset();
			this[send]({PlayerSelected: response});
		});
	},

	WithinPlayer(ui) {
		this[vm].game_screen = 'WithinPlayer';
		this.showButtons(ui);
	},
};

const views = {
	AskRoom() {
		// Don't answer on the same tick.
		window.setTimeout(() => this[vm].askRoom(true), 0);
	},

	Connecting(room) {
		this[vm].screen = 'connecting';
		this[vm].room = room;
	},

	Start(ui) {
		this[vm].screen = 'start';
		this.showButtons(ui);
	},

	Game({game, player, neighbours}) {
		this[vm].screen = 'game';
		let [method, data] = extractType(game);
		this[vm].player = player;
		this[vm].neighbours = neighbours;
		game_views[method].call(this, data);
	},

	GameEditor({players, rulesets, roles_available, roles, settings}) {
		this[vm].screen = 'config_editor';
		// This pattern is described at https://vuejs.org/v2/guide/reactivity.html
		this[vm].config_editor = Object.assign({}, this[vm].config_editor, {
			players,
			rulesets,
			roles_available,
			roles,
			settings,
		});
	},

	Results(results) {
		this[vm].screen = 'results';
		this[vm].results = results;
	},
}

export class Ui {
	constructor(controller) {
		let self = this;
		let version = document.getElementById("version-replaced").innerText;
		this[send] = controller;
		// this[send] = (...args) => { console.log(...args); controller(...args) };
		this[seq] = 0;
		this[vm] = new Vue({
			el: '#main',
			vuetify: new Vuetify({
				theme: {
					themes: {
						light: {
							primary: '#3F51B5',
							secondary: colors.red.darken4,
							tetriary: colors.green.lighten2,
						},
					},
				},
				icons: {
					iconfont: 'mdiSvg',
					values: {
						log_entry_private: {
							component: FontAwesomeIcon,
							props: {
								icon: ['fas', 'mask'],
							}
						},
						log_entry_public: {
							component: FontAwesomeIcon,
							props: {
								icon: ['fas', 'bullhorn'],
							}
						},
						log_entry_visual: {
							component: FontAwesomeIcon,
							props: {
								icon: ['fas', 'eye'],
							}
						},
					}
				},
			}),
			render(h) {
				return h(App, {
					props: {
						version,
						room: this.room,
						screen: this.screen,
						game_active: this.game_active,
						game_screen: this.game_screen,
						players: this.players,
						buttons: this.buttons,
						config_editor: this.config_editor,
						day: this.day,
						results: this.results,
						summary: this.summary,
						player: this.player,
						neighbours: this.neighbours,
					},
					on: {
						debug: self[debug],
						'restart': _ => self[send]('Start'),
						'join-room-name': data => self[send]({Join: data}),
						'button-selected': data => this.$emit('button-selected', data),
						'player-selected': data => this.$emit('player-selected', data),
						'player-add': data => self[send]({AddPlayer: data}),
						'player-del': data => self[send]({RemovePlayer: data}),
						'player-move': ({oldIndex, newIndex}) => self[send]({MovePlayer: [oldIndex, newIndex]}),
						'player-reverse': () => self[send]('ReversePlayers'),
						'roles-changed': data => self[send]({Roles: data}),
						'editor-finish': () => self[send]('StartGame'),
						'day-started': () => self[send]('StartCountdown'),
						'day-ended': () => self[send]('DayEnded'),
						'play-again': () => self[send]('PlayAgain'),
						'play-again-settings': () => self[send]('ConfigureGame'),
						'summary-done': () => self[send]('SummaryDone'),
					},
				});
			},
			data: {
				room: '',
				screen: 'start',
				game_active: false,
				game_screen: '',
				buttons: {},
				players: {},
				config_editor: {},
				day: {},
				results: {},
				summary: [],
			},
			methods: {
				reset() {
					self[seq]++;
					this.$children[0].reset();
				},
				askRoom(restart) {
					this.$children[0].askRoom(restart);
				},
				updateShow() {
					this.$children[0].updateShow();
				},
				showNetworkError(failed) {
					this.$children[0].showNetworkError(failed);
				},
				showIncompatibleVersionError() {
					this.$children[0].showIncompatibleVersionError();
				},
			}
		});
	}

	[debug]() {
		window.location = "#debug=true";
		window.location.reload();
	}

	set(state) {
		if (state.room != this[vm].room) {
			// TODO: Replace with generic URL "sync".
			if (state.room) {
				history.pushState("", "", `#room=${state.room}`);
			} else {
				history.pushState("", "", "");
			}
			this[vm].room = state.room;
		}

		this[vm].game_active = state.game_active;

		let [method, data] = extractType(state.main);

		let prevScreen = this[vm].screen;

		views[method].call(this, data);

		if (this[vm].screen !== prevScreen) {
			this[vm].reset();
		}
	}

	updateVersion() {
		this[vm].updateShow();
	}

	showNetworkError(failed) {
		this[vm].showNetworkError(failed);
	}

	showIncompatibleVersionError() {
		this[vm].showIncompatibleVersionError();
	}

	showButtons(ui) {
		// This pattern is described at https://vuejs.org/v2/guide/reactivity.html
		this[vm].buttons = Object.assign({}, this[vm].buttons, ui.choices, {
			buttonKey: this[seq],
			context: ui.context,
			history: ui.history,
			new_history_len: ui.new_history_len,
		});

		this[vm].$off('button-selected');
		this[vm].$once('button-selected', response => {
			let prev_buttons = this[vm].buttons;
			this[vm].reset();
			this[send]({Answer: response});
			// There are 2 cases.
			// 1. wasm is not loaded yet:
			//    Temporary disable buttons until controller appears and
			//    handles the click, to prevent several clicks in a row.
			// 2. wasm is loaded already:
			//    Controller already synchronously called set() which might
			//    already have called showButtons() with a new set of buttons.
			//    If controller crashes, we want to take screenshot for the
			//    bugreport with buttons as they were prior to crash.
			// Therefore, buttons are disabled after calling controller, but
			// the buttons to be disabled are taken from before calling
			// controller.
			for (let btn of prev_buttons.items) {
				btn.enable = false;
			}
		});
	}
}
