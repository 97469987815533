var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        [
          _c(
            "transition-group",
            { attrs: { name: "anim" } },
            [
              _vm._l(_vm.rulesets, function(ruleset) {
                return [
                  _c("v-subheader", { key: ruleset[0], staticClass: "item" }, [
                    _vm._v(_vm._s(ruleset[1]))
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.rolesInRuleset(ruleset[0]), function(role) {
                    return _c("RoleEditorRow", {
                      key: ruleset[0] + ":" + role.id,
                      staticClass: "item",
                      attrs: {
                        role: role,
                        settings: _vm.settings[role.id],
                        max: _vm.desiredCount,
                        vacancies: _vm.desiredCount - _vm.rolesSum,
                        value: _vm.roleCount[role.id]
                      },
                      on: {
                        minus: function($event) {
                          return _vm.minus(role.id)
                        },
                        plus: function($event) {
                          return _vm.plus(role.id)
                        },
                        updated: _vm.sendUpdates
                      }
                    })
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }