<template>
	<RandomizationSettings
		header="How many cards to flip"
		:strings="['1 card', '2 cards', '3 cards']"
		:values="value.chances"
		@updated="$emit('updated')"
	/>
</template>

<script>
import RandomizationSettings from './RandomizationSettings.vue';

export default {
	name: "ExposerSettings",
	components: {
		RandomizationSettings,
	},
	props: {
		value: Object,
	},
}
</script>
