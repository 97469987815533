<template>
	<v-card>
		<v-card-title primary-title class="title">The story so far</v-card-title>
		<div class="summary_container ml-4">
			<template v-for="({visibility, text}, i) in history">
				<v-icon :key="i + '-icon'" class="summary_icon" :title="icon[visibility].hint" @click="legendShown = true">
					{{icon[visibility].icon}}
				</v-icon>
				<span :key="i + '-text'">
					<RichText :data="text" />
					<strong v-if="i >= history.length - new_history_len">(New!)</strong>
				</span>
			</template>
		</div>
		<CloseableDialog v-model="legendShown" :max-width="400" header="Legend" scrollable>
			<div class="summary_dialog">
				<div class="summary_container ma-3">
					<v-icon class="summary_icon">{{icon.Private.icon}}</v-icon>
					<span>Information which at that moment was available only to you, or to a limited set of people</span>
					<v-icon class="summary_icon">{{icon.Visual.icon}}</v-icon>
					<span>What is visible on the table, but not publicly announced. Players learn it when opening eyes, e.g. while waking up during the night, or when the day begins</span>
					<v-icon class="summary_icon">{{icon.Public.icon}}</v-icon>
					<span>Publicly announced information, known to everyone</span>
				</div>
			</div>
		</CloseableDialog>
		<v-card-actions>
			<slot/>
		</v-card-actions>
	</v-card>
</template>

<script>
import RichText from './RichText.vue';
import CloseableDialog from './CloseableDialog.vue';

const icon = {
	Private: {
		icon: '$log_entry_private',
		hint: 'This information may not be known to all players',
	},
	Public: {
		icon: '$log_entry_public',
		hint: 'This information is known to all players',
	},
	Visual: {
		icon: '$log_entry_visual',
		hint: 'This information is available to players who open eyes',
	},
};

export default {
	name: "Summary",
	components: {
		RichText,
		CloseableDialog,
	},
	props: {
		history: Array,
		new_history_len: Number,
	},
	data() {
		return {
			icon,
			legendShown: false,
		};
	},
}
</script>

<style scoped>
.summary_container {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: start;
	align-items: start;
	column-gap: 1ex;
	row-gap: 3px;
}
.summary_icon {
	justify-self: center;
}
.summary_dialog {
	overflow-y: auto;
}
</style>
