<template>
	<v-list>
		<v-subheader v-if="header">{{header}}</v-subheader>
		<v-list-item v-for="(row, i) in zipped" :key="i">
			<v-list-item-content>
				{{row[0]}}
			</v-list-item-content>
			<v-btn icon small :disabled="row[1] <= 0" @click="click_left(i)"><v-icon>{{mdiChevronLeft}}</v-icon></v-btn>
			<span>{{row[1]}}%</span>
			<v-btn icon small :disabled="row[1] >= 100" @click="click_right(i)"><v-icon>{{mdiChevronRight}}</v-icon></v-btn>
		</v-list-item>
	</v-list>
</template>

<script>
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js';

// https://stackoverflow.com/questions/4856717/javascript-equivalent-of-pythons-zip-function
const zip = (...rows) => [...rows[0]].map((_,c) => rows.map(row => row[c]));

export default {
	name: "RandomizationSettings",
	props: {
		values: Array,
		strings: Array,
		header: String,
	},
	data() {
		return {
			mdiChevronLeft,
			mdiChevronRight,
		}
	},
	computed: {
		zipped() {
			return zip(this.strings, this.values);
		},
	},
	methods: {
		click_left(i) {
			let end = this.values.length - 1;
			if (i === end) {
				end--;
			}
			this.values[i] -= 5;
			this.values[end] += 5;
			this.$emit('updated');
		},
		click_right(i) {
			let end = this.values.length - 1;
			while (i === end || this.values[end] <= 0) {
				end--;
			}
			this.values[i] += 5;
			this.values[end] -= 5;
			this.$emit('updated');
		},
	},
}
</script>
