var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "" },
          model: {
            value: _vm.menuVisible,
            callback: function($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible"
          }
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { href: "." } },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiHome))])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", [_vm._v("Leave Game")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.askRoom(false)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiAccountMultiple))])],
                    1
                  ),
                  _vm._v(" "),
                  _vm.room
                    ? _c("v-list-item-content", [
                        _vm._v("Room: " + _vm._s(_vm.room))
                      ])
                    : _c("v-list-item-content", [_vm._v("Join Room")]),
                  _vm._v(" "),
                  _vm.room
                    ? _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPencil))])],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.game_active
                ? _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.restart()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiReplay))])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-content", [_vm._v("Restart Game")])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("debug")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiWrench))])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", [_vm._v("Debug Mode")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                { attrs: { href: _vm.mailto } },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiBug))])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", [_vm._v("Feedback")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeLog("changelog-pinned.html")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiScriptTextOutline))])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Change Log")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.version))])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      _vm.aboutShown = true
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiInformation))])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", [_vm._v("About")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "primary", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.menuVisible = !_vm.menuVisible
              }
            }
          }),
          _vm._v(" "),
          !_vm.menuVisible
            ? _c("span", { staticClass: "pr-2" }, [_vm._v(_vm._s(_vm.room))])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("header"),
          _vm._v(" "),
          _vm._t("header-ext", null, { slot: "extension" })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-main",
        { ref: "content" },
        [_c("v-container", { attrs: { fluid: "" } }, [_vm._t("default")], 2)],
        1
      ),
      _vm._v(" "),
      _c("Prompt", {
        ref: "join",
        attrs: {
          title: "Enter room code",
          label: "Room",
          counter: 4,
          uppercase: ""
        },
        on: {
          ok: function($event) {
            return _vm.$emit("join-room-name", $event)
          },
          cancel: _vm.joinCancelled
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 400 },
          model: {
            value: _vm.restartShown,
            callback: function($$v) {
              _vm.restartShown = $$v
            },
            expression: "restartShown"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { "justify-space-between": "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [_vm._v("Restart Game")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.restartShown = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("Restart", {
                on: {
                  "play-again": function($event) {
                    return _vm.restartAction("play-again", $event)
                  },
                  "play-again-settings": function($event) {
                    return _vm.restartAction("play-again-settings", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 400 },
          model: {
            value: _vm.updateShown,
            callback: function($$v) {
              _vm.updateShown = $$v
            },
            expression: "updateShown"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { "justify-space-between": "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [_vm._v("Update Game")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.updateShown = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "pa-5 pb-1" }, [
                _vm._v("New version available.")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pa-5 pt-1" }, [
                _c("strong", [_vm._v("Warning:")]),
                _vm._v(
                  " If you are playing right now and choose to upgrade, you will need to restart the game."
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.changeLog("changelog.html")
                        }
                      }
                    },
                    [_vm._v("ChangeLog")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function($event) {
                          _vm.updateShown = false
                        }
                      }
                    },
                    [_vm._v("Later")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.updateNow()
                        }
                      }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "CloseableDialog",
        {
          attrs: { scrollable: "", header: "Change Log" },
          model: {
            value: _vm.changeLogShown,
            callback: function($$v) {
              _vm.changeLogShown = $$v
            },
            expression: "changeLogShown"
          }
        },
        [
          _vm.changeLogText
            ? _c("v-card-text", {
                staticClass: "changelog",
                domProps: { innerHTML: _vm._s(_vm.changeLogText) }
              })
            : _c(
                "div",
                [
                  _c("v-skeleton-loader", { attrs: { type: "article" } }),
                  _vm._v(" "),
                  _c("v-skeleton-loader", { attrs: { type: "article" } })
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "CloseableDialog",
        {
          attrs: { "max-width": 400, scrollable: "", header: "About Werewolf" },
          model: {
            value: _vm.aboutShown,
            callback: function($$v) {
              _vm.aboutShown = $$v
            },
            expression: "aboutShown"
          }
        },
        [
          _c("v-card-text", [
            _c("p", [
              _vm._v("This application lets you play "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://beziergames.com/products/one-night-ultimate-werewolf",
                    target: "_blank"
                  }
                },
                [_vm._v("One Night Ultimate Werewolf")]
              ),
              _vm._v(" and its expansions by "),
              _c(
                "a",
                {
                  attrs: { href: "https://beziergames.com", target: "_blank" }
                },
                [_vm._v("Bezier Games")]
              ),
              _vm._v(' without closing your eyes for the "night".')
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You can play using a single phone by passing it around the table, have every player use their own phone or anything in between."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "It is not affiliated with Bezier Games and made for friends. One of players is advised to buy the game from them."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("The previous incarnation of the project was at "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://ultimatewerewolf.quis.cx",
                    target: "_blank"
                  }
                },
                [_vm._v("ultimatewerewolf.quis.cx")]
              ),
              _vm._v(
                ". It was rewritten from scratch because the old architecture couldn't accommodate all new roles."
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: -1, color: "error" },
          model: {
            value: _vm.networkErrorShown,
            callback: function($$v) {
              _vm.networkErrorShown = $$v
            },
            expression: "networkErrorShown"
          }
        },
        [_vm._v("Cannot connect to server")]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 10000, color: "error" },
          model: {
            value: _vm.incompatibleVersionErrorShown,
            callback: function($$v) {
              _vm.incompatibleVersionErrorShown = $$v
            },
            expression: "incompatibleVersionErrorShown"
          }
        },
        [
          _vm._v(
            "\n\t\tGot an incompatible message from another player. All players must use the same version. After everyone updates the version, you are advised to switch to a new room.\n\t"
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }