<template>
	<v-card flat color="transparent">
		<v-subheader>Chance for seeing 2 cards</v-subheader>
		<v-card-text>
			<v-slider
				v-model="value.chance_double"
				min="0"
				max="1"
				step="0.01"
				:prepend-icon="mdiChevronLeft"
				:append-icon="mdiChevronRight"
				@click:prepend="click_left"
				@click:append="click_right"
				@input="$emit('updated')"
				always-dirty
				thumb-label="always">
				<template v-slot:thumb-label="props">
					<span>{{Math.round(props.value * 100)}}%</span>
				</template>
			</v-slider>
		</v-card-text>
	</v-card>
</template>

<script>
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js';

export default {
	name: "PsychicSettings",
	props: {
		value: Object,
	},
	data() {
		return {
			mdiChevronLeft,
			mdiChevronRight,
		}
	},
	methods: {
		click_left() {
			this.value.chance_double -= 0.05;
			this.$emit('updated');
		},
		click_right() {
			this.value.chance_double += 0.05;
			this.$emit('updated');
		},
	},
}
</script>
