var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-space-around": "" } },
    [
      _c(
        "v-btn",
        {
          attrs: { color: "primary" },
          on: {
            click: function($event) {
              return _vm.$emit("play-again")
            }
          }
        },
        [
          _vm._v("Play again"),
          _c("v-icon", { attrs: { right: "" } }, [
            _vm._v(_vm._s(_vm.mdiReplay))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { color: "secondary" },
          on: {
            click: function($event) {
              return _vm.$emit("play-again-settings")
            }
          }
        },
        [
          _vm._v("Change settings"),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v(_vm._s(_vm.mdiTune))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }