<template>
	<div>
	<div class="subtitle-1">{{round}}</div>
	<v-subheader v-if="remote_players.length">Local players</v-subheader>
	<v-layout column v-for="item in local_players" :key="item.id" pt-3>
		<div :class="{icons: true, enabled: enabled(item)}" @click="click(item)">
			<v-icon v-if="item.done">{{mdiCheck}}</v-icon>
			<v-icon v-else-if="item.locked">{{mdiClockOutline}}</v-icon>
			<v-icon v-else>{{mdiPlay}}</v-icon>
			<Icon v-for="icon in item.icons" :key="icon" :icon="icon"/>
		</div>
		<v-btn :disabled="!enabled(item)" @click="click(item)" color="primary">
			{{item.name}}
		</v-btn>
	</v-layout>
	<v-subheader v-if="remote_players.length">Remote players</v-subheader>
	<v-layout column v-for="item in remote_players" :key="item.id" pt-3>
		<div class="icons" @click="click(item)">
			<v-icon v-if="item.done">{{mdiCheck}}</v-icon>
			<v-icon v-else-if="item.locked">{{mdiClockOutline}}</v-icon>
			<v-btn v-else fab x-small color="primary"><v-icon>{{mdiPlay}}</v-icon></v-btn>
		</div>
		<v-btn disabled color="primary">
			{{item.name}}
		</v-btn>
	</v-layout>
	</div>
</template>

<script>
import {
	mdiCheck,
	mdiClockOutline,
	mdiPlay,
} from '@mdi/js';

export default {
	name: "PlayerSelector",
	props: {
		lock_pending: Number,
		players: Array,
		round: String,
	},
	data() {
		return {
			mdiCheck,
			mdiClockOutline,
			mdiPlay,
		};
	},
	methods: {
		click(item) {
			if (this.enabled(item)) {
				this.$emit('selected', item.id);
			}
		},
		enabled(item) {
			return !item.done && !item.locked && this.lock_pending === null;
		},
	},
	computed: {
		local_players: function () {
			return this.players.filter(p => p.local)
		},
		remote_players: function () {
			return this.players.filter(p => !p.local)
		},
	},
}
</script>

<style scoped>
.icons {
	position: absolute;
	/* match size of .v-btn */
	height: 30px;
	margin-left: 6px;
	margin-top: 6px;
	z-index: 1;
}
.icons * {
	line-height: 36px;
}
.icons > button {
	margin-top: -4px;
}
.icons.enabled * {
	color: #FFFFFF;
}
html body div.v-application .v-icon {
	vertical-align: baseline;
}
</style>
