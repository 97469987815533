<template>
	<div>
		<v-list-item class="role-row">
			<v-list-item-action>
				<v-dialog v-model="help" :max-width="500">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" small><v-icon>{{mdiHelpCircle}}</v-icon></v-btn>
					</template>
					<v-card>
						<v-card-title primary-title class="headline">{{role.name}}</v-card-title>
						<v-card-text>{{role.desc}}</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn outlined @click="help = false" color="primary">Ok</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-list-item-action>
			<v-list-item-content>{{role.name}}</v-list-item-content>
			<v-btn icon small v-if="settings" @click="settingsVisible = !settingsVisible"><v-icon>{{mdiDotsVertical}}</v-icon></v-btn>
			<v-list-item-action v-if="role.id === 'Villager'">{{vacancies}} (the rest)</v-list-item-action>
			<template v-else-if="role.multiple_allowed === 'Sure'">
				<v-btn icon small :disabled="value === 0" @click="$emit('minus')"><v-icon>{{mdiChevronLeft}}</v-icon></v-btn>
				{{value}}
				<v-btn icon small :disabled="value >= max" @click="$emit('plus')"><v-icon>{{mdiChevronRight}}</v-icon></v-btn>
			</template>
			<v-list-item-action v-else>
				<v-switch :inputValue="value" :trueValue="1" :falseValue="0" @change="toggle"/>
			</v-list-item-action>
		</v-list-item>
		<div class="whole-settings-panel" v-if="settings" v-show="settingsVisible">
			<div class="pre-settings-panel"></div>
			<component :is="role.id + 'Settings'" class="settings-panel" v-model="settings" @updated="$emit('updated')" />
			<div class="post-settings-panel"></div>
		</div>
	</div>
</template>

<script>
import AlienSettings from './AlienSettings.vue';
import BodySnatcherSettings from './BodySnatcherSettings.vue';
import CuratorSettings from './CuratorSettings.vue';
import ExposerSettings from './ExposerSettings.vue';
import MorticianSettings from './MorticianSettings.vue';
import PsychicSettings from './PsychicSettings.vue';
import RascalSettings from './RascalSettings.vue';
import {mdiHelpCircle, mdiChevronLeft, mdiChevronRight, mdiDotsVertical} from '@mdi/js';

export default {
	name: 'RoleEditorRow',
	components: {
		/* eslint-disable vue/no-unused-components */
		AlienSettings,
		BodySnatcherSettings,
		CuratorSettings,
		ExposerSettings,
		MorticianSettings,
		PsychicSettings,
		RascalSettings,
		/* eslint-enable vue/no-unused-components */
	},
	props: {
		role: Object,
		max: Number,
		value: Number,
		vacancies: Number,
		settings: [Object, Array],
	},
	data() {
		return {
			help: false,
			settingsVisible: false,
			mdiHelpCircle,
			mdiChevronLeft,
			mdiChevronRight,
			mdiDotsVertical,
		}
	},
	methods: {
		toggle() {
			if (this.value) {
				this.$emit('minus');
			} else {
				this.$emit('plus');
			}
		},
	},
}
</script>

<style scoped>
.role-row {
	box-shadow: 0 2px 2px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 0px 2px 0 rgba(0,0,0,.12);
	z-index: auto;
}
.pre-settings-panel {
	width: 40px;
	box-shadow: inset -11px 0px 3px -10px #dbdbdb;
}
.post-settings-panel {
	width: 10px;
	box-shadow: inset 11px 0px 3px -10px #dbdbdb;
}
.whole-settings-panel .settings-panel {
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,.12),0px 2px 2px -1px rgba(0,0,0,.12),inset 0 10px 5px -10px #dbdbdb;
	flex: 1 1;
}
.whole-settings-panel {
	display: flex;
	padding-bottom: 5px;
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.v-list-item__action {
	margin: 0;
}
</style>
