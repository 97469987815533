<template>
	<v-dialog v-model="shown" :max-width="500" persistent>
		<v-card>
			<v-card-title primary-title class="headline">{{title}}</v-card-title>
			<v-card-text>
				<v-text-field v-model="text" ref="field" v-bind="{label, counter}" :class="{uppercase}" @keyup.enter="ok" @keyup.escape="cancel"></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="cancel">Cancel</v-btn>
				<v-btn color="secondary" @click="ok" :disabled="!text">Ok</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'Prompt',
	props: {
		title: String,
		label: String,
		counter: Number,
		uppercase: Boolean,
	},
	data() {
		return {
			shown: false,
			text: '',
		};
	},
	methods: {
		clear() {
			this.shown = false;
			this.text = '';
			this.$refs.field.blur();
		},
		ok() {
			let text = this.text;
			if (text) {
				if (this.uppercase) {
					text = text.toUpperCase();
				}
				this.$emit('ok', text);
				this.clear();
			}
		},
		cancel() {
			this.$emit('cancel');
			this.clear();
		},
	},
	watch: {
		shown(value) {
			if (value) {
				this.$nextTick(() => {
					this.$nextTick(() => {
						this.$nextTick(() => {
							this.$refs.field.focus();
						});
					});
				});
			}
		},
	},
}
</script>

<style>
.uppercase input {
	text-transform: uppercase;
}
</style>
