<template>
	<v-layout wrap justify-space-around>
		<v-btn color="primary" @click="$emit('play-again')">Play again<v-icon right>{{mdiReplay}}</v-icon></v-btn>
		<v-btn color="secondary" @click="$emit('play-again-settings')">Change settings<v-icon right>{{mdiTune}}</v-icon></v-btn>
	</v-layout>
</template>

<script>
import {mdiReplay, mdiTune} from '@mdi/js';

export default {
	name: 'Restart',
	data() {
		return {
			mdiReplay,
			mdiTune,
		};
	},
}
</script>

<style scoped>
.v-btn {
	margin: 0.2em;
}
</style>
