var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ExternalHeader",
        [
          _vm.screen === "day"
            ? _c("v-toolbar-title", [_vm._v("Day")])
            : _vm.screen === "PlayerSelector"
            ? _c("v-toolbar-title", [_vm._v("Select your name")])
            : _vm.screen === "WithinPlayer"
            ? _c("v-toolbar-title", [_vm._v("Hi " + _vm._s(_vm.player))])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("ExternalHeader", { attrs: { where: "header-ext" } }),
      _vm._v(" "),
      _vm.neighbours.length > 0
        ? _c(
            "v-card",
            { staticClass: "mb-3", attrs: { outlined: "" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "justify-space-between": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c("v-icon", { staticClass: "ma-1" }, [
                            _vm._v(_vm._s(_vm.mdiArrowLeft))
                          ]),
                          _c("span", { staticClass: "neighbour_name" }, [
                            _vm._v(_vm._s(_vm.neighbours[0]))
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "secondary" },
                              on: { click: _vm.wrong_neighbours }
                            },
                            [_vm._v("Wrong neighbours?")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c("span", { staticClass: "neighbour_name" }, [
                            _vm._v(_vm._s(_vm.neighbours[1]))
                          ]),
                          _c("v-icon", { staticClass: "ma-1" }, [
                            _vm._v(_vm._s(_vm.mdiArrowRight))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-snackbar",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function(ref) {
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._b(
                                {
                                  attrs: { color: "pink", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.wrong_neighbours_shown = false
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              [_vm._v("Close")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3512501745
                  ),
                  model: {
                    value: _vm.wrong_neighbours_shown,
                    callback: function($$v) {
                      _vm.wrong_neighbours_shown = $$v
                    },
                    expression: "wrong_neighbours_shown"
                  }
                },
                [
                  _vm._v(
                    "\n\t\tPlease say aloud that your neighbours are wrong.\n\t\t"
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen === "PlayerSelector"
        ? _c(
            "PlayerSelector",
            _vm._b(
              {
                on: {
                  selected: function($event) {
                    return _vm.$emit("player-selected", $event)
                  }
                }
              },
              "PlayerSelector",
              _vm.players,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen === "WithinPlayer"
        ? _c(
            "ButtonList",
            _vm._b(
              {
                attrs: { color: "tetriary" },
                on: {
                  selected: function($event) {
                    return _vm.$emit("button-selected", $event)
                  }
                }
              },
              "ButtonList",
              _vm.buttons,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen === "summary"
        ? _c(
            "Summary",
            {
              attrs: {
                history: _vm.summary.history,
                new_history_len: _vm.summary.new_history_len
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", block: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("summary-done")
                    }
                  }
                },
                [_vm._v("Got it")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen === "day"
        ? _c(
            "Day",
            _vm._b(
              {
                on: {
                  start: function($event) {
                    return _vm.$emit("day-started")
                  },
                  finish: function($event) {
                    return _vm.$emit("day-ended")
                  }
                }
              },
              "Day",
              _vm.day,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }