<template>
	<div>
	<ExternalHeader>
		<v-toolbar-title v-if="screen === 'day'">Day</v-toolbar-title>
		<v-toolbar-title v-else-if="screen === 'PlayerSelector'">Select your name</v-toolbar-title>
		<v-toolbar-title v-else-if="screen === 'WithinPlayer'">Hi {{player}}</v-toolbar-title>
	</ExternalHeader>
	<ExternalHeader where="header-ext" />
	<v-card v-if="neighbours.length > 0" outlined class="mb-3">
		<v-container>
			<v-layout row justify-space-between align-center>
				<v-flex shrink><v-icon class="ma-1">{{mdiArrowLeft}}</v-icon><span class="neighbour_name">{{neighbours[0]}}</span></v-flex>
				<v-flex shrink><v-btn outlined color="secondary" @click="wrong_neighbours">Wrong neighbours?</v-btn></v-flex>
				<v-flex shrink><span class="neighbour_name">{{neighbours[1]}}</span><v-icon class="ma-1">{{mdiArrowRight}}</v-icon></v-flex>
			</v-layout>
		</v-container>
		<v-snackbar v-model="wrong_neighbours_shown" top>
			Please say aloud that your neighbours are wrong.
			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="wrong_neighbours_shown = false">Close</v-btn>
			</template>
		</v-snackbar>
	</v-card>
	<PlayerSelector
		v-if="screen === 'PlayerSelector'"
		@selected="$emit('player-selected', $event)"
		v-bind="players"
		/>
	<ButtonList
		v-if="screen === 'WithinPlayer'"
		@selected="$emit('button-selected', $event)"
		v-bind="buttons"
		color="tetriary"
		/>
	<Summary v-if="screen === 'summary'" :history="summary.history" :new_history_len="summary.new_history_len">
		<v-btn outlined block color="primary" @click="$emit('summary-done')">Got it</v-btn>
	</Summary>
	<Day v-if="screen === 'day'" v-bind="day" @start="$emit('day-started')" @finish="$emit('day-ended')" />
	</div>
</template>

<script>
import ExternalHeader from './ExternalHeader.vue';
import PlayerSelector from './PlayerSelector.vue';
import ButtonList from './ButtonList.vue';
import Summary from './Summary.vue';
import Day from './Day.vue';
import {
	mdiLockOpenOutline,
	mdiLock,
	mdiAccountSwitch,
	mdiArrowLeft,
	mdiArrowRight
} from '@mdi/js';

export default {
	name: "Game",
	components: {
		ExternalHeader,
		PlayerSelector,
		ButtonList,
		Summary,
		Day,
	},
	props: {
		screen: String,
		player: String,
		players: Object,
		// TODO: pass history outside of buttons
		buttons: Object,
		summary: Object,
		day: Object,
		neighbours: Array,
	},
	data() {
		return {
			wrong_neighbours_shown: false,
			mdiLock,
			mdiLockOpenOutline,
			mdiAccountSwitch,
			mdiArrowLeft,
			mdiArrowRight,
		}
	},
	computed: {
		private_screen() {
			return this.screen === 'summary' || this.screen === 'WithinPlayer';
		},
	},
	methods: {
		wrong_neighbours() {
			this.wrong_neighbours_shown = true;
		},
	},
}
</script>

<style scoped>
.neighbour_name {
	vertical-align: middle;
}
</style>
