<template>
	<v-layout column align-space-around>
		<v-flex align-self-center>
		<VueCountdown ref="countdown" :time="timeout_ms" :auto-start="started" @countdownend="finish" class="display-3">
			<template slot-scope="props">
				{{props.minutes}}:{{props.seconds}}
			</template>
		</VueCountdown>
		</v-flex>
		<v-container fluid>
		<v-layout wrap>
			<v-flex grow v-if="public_info.length > 0" ma-1>
				<v-card>
					<ul class="pa-5 ml-5">
						<li v-for="(info, i) in public_info" :key="i"><RichText :data="info" /></li>
					</ul>
				</v-card>
			</v-flex>
			<v-flex ma-1>
				<v-card>
					<v-card-title primary-title class="title">These roles are in the game</v-card-title>
					<v-list>
						<v-subheader v-if="dusk_roles.length > 0">Dusk</v-subheader>
						<DayRoleRow v-for="r in dusk_roles" :key="r.role" :role="r" />
						<v-subheader v-if="night_roles.length > 0">Night</v-subheader>
						<DayRoleRow v-for="r in night_roles" :key="r.role" :role="r" />
						<v-subheader v-if="nowake_roles.length > 0">No-wake</v-subheader>
						<DayRoleRow v-for="r in nowake_roles" :key="r.role" :role="r" />
					</v-list>
				</v-card>
			</v-flex>
			<v-flex ma-1>
				<v-card>
					<v-card-title primary-title class="title">Players</v-card-title>
					<ul class="pa-5 ml-5">
						<li v-for="player in players" :key="player">{{player}}</li>
					</ul>
				</v-card>
			</v-flex>
		</v-layout>
		</v-container>
		<v-layout wrap justify-space-around>
			<v-btn color="primary" :disabled="started" @click="start">Start countdown<v-icon right>{{mdiPlay}}</v-icon></v-btn>
			<v-btn color="secondary" :disabled="!started" @click="finish">Start voting<v-icon right>{{mdiStop}}</v-icon></v-btn>
		</v-layout>
	</v-layout>
</template>

<script>
import DayRoleRow from './DayRoleRow.vue';
import RichText from './RichText.vue';
import VueCountdown from '@xkeshi/vue-countdown';
import {mdiPlay, mdiStop} from '@mdi/js';

export default {
	name: "Day",
	components: {
		DayRoleRow,
		RichText,
		VueCountdown,
	},
	props: {
		timeout_ms: Number,
		started: Boolean,
		dusk_roles: Array,
		night_roles: Array,
		nowake_roles: Array,
		public_info: Array,
		players: Array,
	},
	data() {
		return {
			mdiPlay,
			mdiStop,
		};
	},
	methods: {
		start() {
			this.$emit("start");
		},
		finish() {
			this.$emit("finish");
		},
	},
}
</script>
