<script>
import Layout from './Layout.vue';
import Game from './Game.vue';
import Start from './Start.vue';
import GameConfigEditor from './GameConfigEditor.vue';
import Results from './Results.vue';
import Connecting from './Connecting.vue';

export default {
	name: "App",
	props: {
		version: String,
		room: String,
		screen: String,
		game_active: Boolean,
		game_screen: String,
		players: Object,
		buttons: Object,
		config_editor: Object,
		day: Object,
		results: Object,
		summary: Array,
		player: String,
		neighbours: Array,
	},
	data() {
		return {
			header: null,
			'header-ext': null,
		};
	},
	provide() {
		return {
			registerHeader: (where, header) => {
				this[where] = header;
			},
		};
	},
	methods: {
		reset() {
			this.$refs.layout.reset();
		},
		updateShow() {
			this.$refs.layout.updateShow();
		},
		askRoom(restart) {
			this.$refs.layout.askRoom(restart);
		},
		showNetworkError(failed) {
			this.$refs.layout.showNetworkError(failed);
		},
		showIncompatibleVersionError() {
			this.$refs.layout.showIncompatibleVersionError();
		},
	},
	render(h) {
		let screen = null;
		switch (this.screen) {
			case 'start':
				screen = h(Start, {
					props: {
						buttons: this.buttons.items,
					},
					on: {
						selected: e => this.$emit('button-selected', e),
					}
				});
				break;
			case 'game':
				screen = h(Game, {
					props: {
						screen: this.game_screen,
						player: this.player,
						players: this.players,
						buttons: this.buttons,
						summary: this.summary,
						day: this.day,
						neighbours: this.neighbours,
					},
					on: {
						'player-selected': e => this.$emit('player-selected', e),
						'button-selected': e => this.$emit('button-selected', e),
						'summary-done': () => this.$emit('summary-done'),
						'day-started': () => this.$emit('day-started'),
						'day-ended': () => this.$emit('day-ended'),
					}
				});
				break;
			case 'config_editor':
				screen = h(GameConfigEditor, {
					props: this.config_editor,
					on: {
						'roles-changed': e => this.$emit('roles-changed', e),
						'player-add': e => this.$emit('player-add', e),
						'player-del': e => this.$emit('player-del', e),
						'player-move': e => this.$emit('player-move', e),
						'player-reverse': () => this.$emit('player-reverse'),
						'editor-finish': () => this.$emit('editor-finish'),
					}
				});
				break;
			case 'results':
				screen = h(Results, {
					props: this.results,
					on: {
						again: () => this.$emit('play-again'),
						settings: () => this.$emit('play-again-settings'),
					}
				});
				break;
			case 'connecting':
				screen = h(Connecting);
				break;
		}
		return h(Layout, {
			ref: "layout",
			props: {
				game_active: this.game_active,
				room: this.room,
				version: this.version,
			},
			on: {
				'debug': () => this.$emit('debug'),
				'join-room-name': room => this.$emit('join-room-name', room),
				'play-again': room => this.$emit('play-again', room),
				'restart': room => this.$emit('restart', room),
				'play-again-settings': room => this.$emit('play-again-settings', room),
			},
		}, [
			h('transition', {
				props: {
					mode: 'out-in',
					name: 'fade',
				}
			}, [screen]),
			h('template', {
				slot: 'header',
			}, this.header),
			h('template', {
				slot: 'header-ext',
			}, this['header-ext']),
		])
	},
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.1s ease;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
</style>
