var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RandomizationSettings", {
    attrs: {
      header: "How many cards to view",
      strings: ["0 cards", "1 card", "2 cards"],
      values: _vm.value.chances
    },
    on: {
      updated: function($event) {
        return _vm.$emit("updated")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }