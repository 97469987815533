var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "playerlistcontainer" } },
    [
      _c(
        "SortableList",
        {
          staticClass: "list",
          attrs: {
            lockAxis: "y",
            value: _vm.players,
            useDragHandle: true,
            appendTo: "#playerlistcontainer"
          },
          on: { "sort-end": _vm.dragged }
        },
        [
          _c(
            "transition-group",
            { attrs: { name: _vm.anim } },
            _vm._l(_vm.players, function(ref, index) {
              var name = ref.name
              var local = ref.local
              return _c(
                "SortableListItem",
                { key: name, staticClass: "item", attrs: { index: index } },
                [
                  _c(
                    "v-list-item-action",
                    {
                      directives: [{ name: "handle", rawName: "v-handle" }],
                      staticClass: "handle"
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDrag))])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", { class: { remote: !local } }, [
                    _vm._v(_vm._s(name))
                  ]),
                  _vm._v(" "),
                  !local
                    ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiAntenna))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("player-del", name)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDelete))])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { fab: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { fab: "", color: "secondary", title: "Reverse" },
              on: {
                click: function($event) {
                  return _vm.$emit("player-reverse")
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiShuffle))])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { fab: "", color: "secondary", title: "Add player" },
              on: {
                click: function($event) {
                  _vm.$refs.add.shown = true
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiAccountPlus))])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Prompt", {
        ref: "add",
        attrs: { title: "New player", label: "Name" },
        on: {
          ok: function($event) {
            return _vm.$emit("player-add", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }