var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "align-space-around": "" } },
    [
      _c("ExternalHeader"),
      _vm._v(" "),
      _c("ExternalHeader", { attrs: { where: "header-ext" } }),
      _vm._v(" "),
      _c("v-flex", { attrs: { "align-self-center": "", "display-1": "" } }, [
        _vm._v("\n\t\tConnecting\n\t")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }