<template>
	<v-layout column align-space-around>
		<ExternalHeader />
		<ExternalHeader where="header-ext" />
		<v-flex align-self-center display-1>
			Connecting
		</v-flex>
	</v-layout>
</template>

<script>
import ExternalHeader from './ExternalHeader.vue';
export default {
	name: "Connecting",
	components: {
		ExternalHeader,
	},
}
</script>
