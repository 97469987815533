<template>
	<div>
	<v-list>
		<transition-group name="anim">
		<template v-for="ruleset in rulesets">
			<v-subheader :key="ruleset[0]" class="item">{{ruleset[1]}}</v-subheader>
			<RoleEditorRow v-for="role in rolesInRuleset(ruleset[0])"
				:key="ruleset[0]+':'+role.id"
				:role="role"
				:settings="settings[role.id]"
				:max="desiredCount"
				:vacancies="desiredCount - rolesSum"
				:value="roleCount[role.id]"
				@minus="minus(role.id)"
				@plus="plus(role.id)"
				@updated="sendUpdates"
				class="item"
				/>
		</template>
		</transition-group>
	</v-list>
	</div>
</template>

<script>
import RoleEditorRow from './RoleEditorRow.vue';

export default {
	name: "RoleEditor",
	components: {
		RoleEditorRow,
	},
	props: {
		desiredCount: Number,
		rulesets: Array,
		roles: Array,
		roles_available: Array,
		settings: Object,
		filter: String,
	},
	data() {
		let roleCount = {};
		for (let role of this.roles_available) {
			roleCount[role.id] = 0;
		}
		for (let role of this.roles) {
			++roleCount[role];
		}
		return {
			roleCount,
		}
	},
	computed: {
		rolesSum() {
			return this.roles.length;
		},
		valid() {
			return this.rolesSum <= this.desiredCount;
		},
	},
	methods: {
		rolesInRuleset(ruleset) {
			let filter = (this.filter || '').toLowerCase();
			return this.roles_available.filter(role =>
				role.rule_set === ruleset &&
				role.name.toLowerCase().includes(filter));
		},
		minus(role) {
			if (this.roleCount[role] > 0) {
				--this.roleCount[role];
				this.sendUpdates();
			}
		},
		plus(role) {
			++this.roleCount[role];
			this.sendUpdates();
		},
		sendUpdates() {
			let selectedRoles = [];
			for (let id in this.roleCount) {
				selectedRoles.push(...Array(this.roleCount[id]).fill(id));
			}
			this.$emit('roles-changed', {roles: selectedRoles, settings: this.settings});
		},
	},
}
</script>

<style scoped>
.anim-enter, .anim-leave-to {
	opacity: 0;
}
.anim-leave-active {
	position: absolute;
	width: 100%;
}
.item {
	transition: all 0.3s ease;
}
</style>
