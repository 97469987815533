<template>
	<div>
		<ExternalHeader>
			<v-toolbar-title>Roles selected: {{roles.length}} / {{desiredCount}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click="tryFinish" title="Next"><v-icon>{{mdiCheck}}</v-icon></v-btn>
		</ExternalHeader>
		<ExternalHeader where="header-ext">
			<v-tabs v-model="tab" background-color="transparent">
				<v-tab :key="0">Players</v-tab>
				<v-tab :key="1">Roles</v-tab>
			</v-tabs>
			<v-text-field color="secondary" v-if="tab == 1" v-model="filter" clearable label="Filter"></v-text-field>
		</ExternalHeader>
		<v-tabs-items v-model="tab">
			<v-tab-item :key="0">
				<PlayersEditor
					:players="players"
					@player-add="$emit('player-add', $event)"
					@player-del="$emit('player-del', $event)"
					@player-move="$emit('player-move', $event)"
					@player-reverse="$emit('player-reverse')"
					/>
			</v-tab-item>
			<v-tab-item :key="1">
				<RoleEditor
					:filter="filter"
					:desiredCount="desiredCount"
					:rulesets="rulesets"
					:roles="roles"
					:roles_available="roles_available"
					:settings="settings"
					@roles-changed="$emit('roles-changed', $event)"
					/>
			</v-tab-item>
		</v-tabs-items>
		<v-snackbar v-model="error_shown" top>
			{{error_text}}
			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="error_shown = false">Close</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
import ExternalHeader from './ExternalHeader.vue';
import PlayersEditor from './PlayersEditor.vue';
import RoleEditor from './RoleEditor.vue';
import {mdiCheck} from '@mdi/js';

export default {
	name: 'GameConfigEditor',
	components: {
		PlayersEditor,
		RoleEditor,
		ExternalHeader,
	},
	props: {
		players: Array,
		rulesets: Array,
		roles: Array,
		settings: Object,
		roles_available: Array,
	},
	data() {
		return {
			error_shown: false,
			error_text: '',
			tab: 0,
			filter: '',
			mdiCheck,
		};
	},
	computed: {
		desiredCount() {
			return this.players.length + 3;
		},
	},
	methods: {
		tryFinish() {
			if (this.players.length < 3) {
				this.error_text = 'Need at least 3 players';
				this.error_shown = true;
				return;
			}
			if (this.tab === 0) {
				this.tab = 1;
				return;
			}
			if (this.roles.length > this.desiredCount) {
				this.error_text = 'Too many roles selected';
				this.error_shown = true;
				return;
			}
			if (this.roles.length === 0 && !confirm("Do you really want to play only with Villagers? This won't be a very interesting game.")) {
				return;
			}
			this.$emit('editor-finish');
		},
	},
}
</script>
