<template>
	<v-dialog :value="value" @input="$emit('input', $event.value)" :scrollable="scrollable" :max-width="maxWidth">
		<v-card>
			<v-layout justify-space-between>
				<v-card-title primary-title class="headline">{{header}}</v-card-title>
				<v-btn icon @click="close()"><v-icon>{{mdiClose}}</v-icon></v-btn>
			</v-layout>
			<v-divider></v-divider>
			<slot/>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="close()">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
	name: 'CloseableDialog',
	props: {
		scrollable: Boolean,
		value: Boolean,
		header: String,
		maxWidth: Number,
	},
	data() {
		return {
			mdiClose,
		};
	},
	methods: {
		close() {
			this.$emit('input', false);
		},
	},
};
</script>
