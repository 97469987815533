var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "align-space-around": "" } },
    [
      _c(
        "v-flex",
        { attrs: { "align-self-center": "" } },
        [
          _c("VueCountdown", {
            ref: "countdown",
            staticClass: "display-3",
            attrs: { time: _vm.timeout_ms, "auto-start": _vm.started },
            on: { countdownend: _vm.finish },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(props.minutes) +
                        ":" +
                        _vm._s(props.seconds) +
                        "\n\t\t"
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _vm.public_info.length > 0
                ? _c(
                    "v-flex",
                    { attrs: { grow: "", "ma-1": "" } },
                    [
                      _c("v-card", [
                        _c(
                          "ul",
                          { staticClass: "pa-5 ml-5" },
                          _vm._l(_vm.public_info, function(info, i) {
                            return _c(
                              "li",
                              { key: i },
                              [_c("RichText", { attrs: { data: info } })],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { "ma-1": "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "title",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v("These roles are in the game")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list",
                        [
                          _vm.dusk_roles.length > 0
                            ? _c("v-subheader", [_vm._v("Dusk")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.dusk_roles, function(r) {
                            return _c("DayRoleRow", {
                              key: r.role,
                              attrs: { role: r }
                            })
                          }),
                          _vm._v(" "),
                          _vm.night_roles.length > 0
                            ? _c("v-subheader", [_vm._v("Night")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.night_roles, function(r) {
                            return _c("DayRoleRow", {
                              key: r.role,
                              attrs: { role: r }
                            })
                          }),
                          _vm._v(" "),
                          _vm.nowake_roles.length > 0
                            ? _c("v-subheader", [_vm._v("No-wake")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.nowake_roles, function(r) {
                            return _c("DayRoleRow", {
                              key: r.role,
                              attrs: { role: r }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { "ma-1": "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "title",
                          attrs: { "primary-title": "" }
                        },
                        [_vm._v("Players")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "pa-5 ml-5" },
                        _vm._l(_vm.players, function(player) {
                          return _c("li", { key: player }, [
                            _vm._v(_vm._s(player))
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { wrap: "", "justify-space-around": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: _vm.started },
              on: { click: _vm.start }
            },
            [
              _vm._v("Start countdown"),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v(_vm._s(_vm.mdiPlay))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "secondary", disabled: !_vm.started },
              on: { click: _vm.finish }
            },
            [
              _vm._v("Start voting"),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v(_vm._s(_vm.mdiStop))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }